<template>
  <div class="container-fluid py-3">
    <!-- begin::Datatable actions -->
    <div class="mb-7">
      <div class="row align-items-center">
        <div class="col-12 col-lg">
          <div class="input-icon">
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              @keyup.enter="$refs.datatable.search"
              @keyup="searchDebounced"
            >
            <span>
              <i>
                <font-awesome-icon :icon="['fas', 'search']" />
              </i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- end::Datatable actions -->

    <!-- begin::Datatable -->
    <datatable
      ref="datatable"
      :url="datatable.url"
      :fields="datatable.fields"
      :search-fields="datatable.searchFields"
      :on-pagination-data="onPaginationData"

      :filters="datatable.filters"

      :transform="transform"
    >
      <!-- begin::Link/unlink -->
      <div slot="link" slot-scope="props">
        <!-- begin::Link button -->
        <button
          v-if="isCompanyLinked(props.rowData) === false"
          class="btn w-150px btn-info"
          :disabled="props.rowData._meta.guid === company._meta.guid || $auth.hasPermission('companies.equipment.link') === false"
          @click.prevent="modifyEquipmentLink(props.rowData)"
        >
          Link
        </button>
        <!-- end::Link button -->

        <button
          v-if="isCompanyLinked(props.rowData)"
          class="btn w-150px btn-danger"
          :disabled="props.rowData._meta.guid === company._meta.guid || $auth.hasPermission('companies.equipment.remove') === false"
          @click.prevent="modifyEquipmentLink(props.rowData)"
        >
          Unlink
        </button>
      </div>
      <!-- end::Link/unlink -->
    </datatable>
    <!-- end::Datatable -->

    <!-- begin::Datatable footer -->
    <div class="datatable datatable-default">
      <div class="datatable-pager datatable-paging-loaded">
        <datatable-pagination
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        />

        <datatable-pagination-info
          ref="pagination.info"
          @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
        />
      </div>
    </div>
    <!-- end::Datatable footer -->
  </div>
</template>

<script>
import { DatatableMixin } from '@vedicium/metronic-vue';
import { CompanyClass as Company, company as companyLib } from '@vedicium/core-vue/build/modules/company';
import Equipment from '@/libs/classes/equipment';
import Device from '@/libs/classes/device';

export default {
  mixins: [DatatableMixin],
  props: {
    equipment: {
      type: Equipment,
      default: null,
    },
    device: {
      type: Device,
      default: null,
    },
  },
  data () {
    return {
      company: companyLib.current,
      datatable: {
        url: Company.uri,

        fields: [{
          name: 'name',
          title: 'Name',
          sortField: 'name',
          titleClass: 'w-200px',
          dataClass: 'w-200px',
        }, {
          name: 'company_id',
          title: 'Company ID',
          sortField: 'company_id',
          titleClass: 'w-200px',
          dataClass: 'w-200px',
        }, {
          name: 'company_type',
          title: 'Company type',
          sortField: 'company_type',
          formatter: (value) => this.$tu(`companies.type.${value}.name`) || value,
          titleClass: 'w-200px',
          dataClass: 'w-200px',
        }, {
          name: 'location.description',
          title: 'Location',
          sortField: 'location.description',
          titleClass: 'w-200px',
          dataClass: 'w-200px',
          formatter (value) {
            return `${value || '<span class="font-italic">No location set</span>'}`;
          },
        }, {
          name: 'link',
          title: '',
          titleClass: 'w-100px',
          dataClass: 'w-100px text-right',
        }],

        searchFields: ['name', 'company_id', 'company_type', 'location.description'],

        filters: {},
      },
    };
  },

  methods: {
    transform (data) {
      const response = { ...data };
      if (response.data && Array.isArray(response.data)) {
        response.data = response.data.map((row) => new Company().merge(row));
      }
      return response;
    },

    isCompanyLinked (company = null) {
      if (!company || company instanceof Company === false || !company.equipment) {
        return false;
      }

      return (company.equipment ? company.equipment.includes(this.equipment._meta.guid) : false);
    },

    async modifyEquipmentLink (company = null) {
      if (!company || company instanceof Company === false) {
        return;
      }

      // Update company
      const isCompanyLinked = this.isCompanyLinked(company);
      if (isCompanyLinked === true) {
        // Unink company
        const vm = this;
        vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
          title: 'Unlink company',
          message: `Are you sure that you want to unlink <strong>${company.name}</strong>?<br /><br />Please type the name of the company to confirm:`,
          verifyString: company.name,
          async onSubmit () {
            vm.$refs.datatable.setLoading(true);
            try {
              await vm.$ws.delete(`${Company.uri}/${company._meta.guid}/equipment/${vm.equipment._meta.guid}`);
            } catch (e) {
              vm.$errors.handle(e, { ui_element: true });
              console.error(e);
              return;
            } finally {
              vm.$refs.datatable.setLoading(false);
            }

            vm.$refs.datatable.refresh({ query: { disable_cache: true } });
          },
        });
      } else {
        // Link company
        const vm = this;
        vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
          title: 'Link company',
          message: `Are you sure that you want to link <strong>${company.name}</strong> to ${this.equipment.alias || this.equipment.serial}?`,
          variant: 'primary',
          buttonText: 'Link',
          async onSubmit () {
            vm.$refs.datatable.setLoading(true);
            try {
              await vm.$ws.put(`${Company.uri}/${company._meta.guid}/equipment/${vm.equipment._meta.guid}/link`);
            } catch (e) {
              vm.$errors.handle(e, { ui_element: true });
              console.error(e);
              return;
            } finally {
              vm.$refs.datatable.setLoading(false);
            }

            vm.$refs.datatable.refresh({ query: { disable_cache: true } });
          },
        });
      }
    },
  },
};
</script>
